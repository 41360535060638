import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Form, Input, Row, Col, Spin } from 'antd'
import { useMutation } from 'react-query'
import CustomAlert from '../../../../components/common/CAlert'
import CButton from '../../../../components/common/CButton'
import CInput from '../../../../components/common/CInput'
import SuccessModal from '../../../../components/common/SuccessModal'
import {
  addCategory,
  addEventType,
  getCategoryById
} from '../../../../services/lov'
import { Theme } from '../../../../Theme'
import ImageUploader from '../../../../components/common/ImageUploader'
import SubCategoriesList from './SubCategories/SubCategoriesList'

const AddCategories: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { type, id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [categoryDetails, setCategoryDetails] = useState<{
    [key: string]: any
  }>({})
  const [image, setImageFile] = useState<File | null>(null)
  const [subCategoriesCount, setSubCategoriesCount] = useState(0)

  const handleSubCategoriesCount = (count: number) => {
    setSubCategoriesCount(count)
  }

  const handleFileSelect = (file: File | null) => {
    setImageFile(file)
  }
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const getTitle = () => {
    if (type == 'view' && id != 'add') {
      return 'Category Details'
    }
    return type == 'edit' ? 'Category Details' : 'Add New Ctegory'
  }

  const { mutate, isLoading } = useMutation(addCategory, {
    onSuccess: response => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  const { mutate: fetchParentCategory, isLoading: isFetchingCategory } =
    useMutation((id: any) => getCategoryById(id), {
      onSuccess: response => {
        if (response.succeeded) {
          setCategoryDetails(response.data)
          form.setFieldsValue({
            Name: categoryDetails.name,
            NameAr: categoryDetails.nameAr,
          })
          console.log('details by id', categoryDetails)
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    })

  useEffect(() => {
    if (type !== 'add' && id !== 'new') {
      fetchParentCategory(id)
    }
  }, [type, id, fetchParentCategory])

  // useEffect(() => {
  //   if (location.state?.record) {
  //     form.setFieldsValue({
  //       Name: location.state.record.name
  //     })
  //   }
  // }, [location.state])

  const handleSave = (values: any) => {
    console.log('values', values)

    const obj = {
      ...values,
      ParentId: null
    }

    const viewObj = {
      Name: values.Name,
      NameAr:values.NameAr,
      ParentId: values.ParentId,
      ...(categoryDetails && {
        id: categoryDetails.id,
        imagePath: categoryDetails.imagePath
      })
    }
    const formData = new FormData()
    // formData.append('ParentId','null')
    // for (const key in values) {
    //   formData.append(key, values[key])
    // }
    if (image) {
      formData.append('image', image)
    }
    formData.append('obj', JSON.stringify(categoryDetails ? viewObj : obj))

    // formData.append('obj', JSON.stringify(obj));

    // if (record) {
    //   formData.append('id', record.id)
    // }
    mutate(formData)
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{t('Categories')}</p>
        </div>
        {type == 'view' && id != 'add' && (
          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <p className='user-name'>{getTitle()}</p>
            <div className='view-container'>
              <div className='view-container-content'>
                <div>
                  <p className='label m-0'>Category Name</p>
                  <p className='detail m-0'>{categoryDetails?.name}</p>
                </div>
                <div className='dir-rtl'>
                  <p className='label m-0'>اسم الفئة</p>
                  <p className='detail m-0'>
                    {categoryDetails?.nameAr ? categoryDetails?.nameAr : '-'}
                  </p>
                </div>
                <div>
                  <p className='label m-0'>SubCategory </p>
                  <p className='detail m-0'>{subCategoriesCount}</p>
                </div>
                <button
                  className='btn-unfilled'
                  onClick={() =>
                    navigate(`/jive/categories/edit/${id}`, {
                      state: { record: categoryDetails }
                    })
                  }
                >
                  <img
                    src={Theme.icons.icn_edit}
                    alt='icn_user_add'
                    style={{ height: '15px' }}
                  />
                  Edit
                </button>
              </div>
              {categoryDetails?.imagePath && (
                <div
                  className='image-preview mt-3'
                  style={{ pointerEvents: 'none', opacity: 0.5 }}
                >
                  <p className='label m-0 mb-2'>{t('Parent Category Image')}</p>

                  <ImageUploader
                    label='Drag & Drop or Upload'
                    preview={categoryDetails?.imagePath}
                    onFileSelected={handleFileSelect}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {type != 'view' && (
          <div>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSave}
              style={{
                backgroundColor: Theme.colors.white,
                padding: '24px',
                borderRadius: '16px'
              }}
            >
              <p className='user-name'>{getTitle()}</p>

              <>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      name='Name'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the category name'
                        }
                      ]}
                    >
                      <CInput
                        label='Category Name'
                        placeholder='Enter Category Name'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name='NameAr'
                      rules={[
                        {
                          required: true,
                          message: 'الرجاء إدخال اسم الفئة'
                        }
                      ]}
                      className='dir-rtl'
                    >
                      <CInput
                        label='اسم الفئة'
                        placeholder='أدخل اسم الفئة'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label='Category Image' style={{ fontSize: '14px' }}>
                  <ImageUploader
                    label='Drag & Drop or Upload'
                    preview={categoryDetails?.imagePath}
                    onFileSelected={handleFileSelect}
                  />
                </Form.Item>

                <Row gutter={8} style={{ marginTop: '15px' }}>
                  <Col span={4}>
                    <CButton
                      loading={isLoading}
                      style={{ width: '100%' }}
                      htmlType='submit'
                    >
                      {categoryDetails ? 'Save' : 'Add'}
                    </CButton>
                  </Col>
                </Row>
              </>
            </Form>
            <SuccessModal
              visible={isSuccess}
              message={
                categoryDetails?.id
                  ? 'Category updated successfully'
                  : 'You have added new category successfully.'
              }
              onOk={() => navigate('/jive/eventPolicyManagementList')}
            />
            <CustomAlert
              message={alert.message}
              type={alert.type as 'success' | 'info' | 'warning' | 'error'}
              visible={alert.visible}
              onClose={() => setAlert({ ...alert, visible: false })}
              duration={3000}
            />
          </div>
        )}
      </div>
      {id != 'new' && (
        <div style={{ padding: '20px', color: Theme.colors.black }}>
          <SubCategoriesList
            id={id}
            heading='List of Sub Categories'
            onCountUpdate={handleSubCategoriesCount}
          />
        </div>
      )}
    </>
  )
}

export default AddCategories
