import React, { useState } from "react";
import CustomTabs from "../../../components/common/CTabs";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../components/common/CAlert";
import { getAllAdminServiceOrder } from "../../../services/booking";
import _ from 'lodash';
import { Tag } from "antd";

const tabs = [
    { key: 'BookingRequest', label: 'Booking Request' },
    { key: 'Approved', label: 'Approved' },
    { key: 'Cancelled', label: 'Cancelled' },
];

const ServiceBookingList: React.FC = () => {
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const activeKey = searchParams.get('Status') || 'BookingRequest';
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";
    const sortOrder = searchParams.get('SortOrder') || "z";

    const handleTabChange = (key: string) => {
        setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
    };

    // Pagination handling
    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ Status: activeKey, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
    };

    const handleSearchChange = _.debounce((e: any) => {
        setSearchParams({ Status: activeKey, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    }, 1000)

    const handleSorting = () => {
        const newSortOrder = sortOrder === 'z' ? 'a' : 'z';
        setSearchParams({
            Status: activeKey,
            PageNumber: pageNumber.toString(),
            PageSize: pageSize.toString(),
            Search: search,
            SortOrder: newSortOrder,
        }, { replace: true });
    };

    const columns: ColumnsType = [
        {
            title: t("ID"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("Customer Name"),
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: t("Phone Number"),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: t("Vendor Name"),
            dataIndex: 'vendorName',
            key: 'vendorName',
        },
        {
            title: t("event_type"),
            dataIndex: 'eventTypeName',
            key: 'serviceCount',
            render: (text, record) => (
                <Tag
                    color={Theme.colors.primaryBase}
                    className="status-tag w-75"
                    style={{ borderColor: Theme.colors.primaryBase }}
                >
                    <span style={{ fontSize: '20px', lineHeight: '1', color: Theme.colors.primaryBase }}>•</span>
                    <span style={{ color: Theme.colors.primaryBase }}>{text}</span>
                </Tag>
            )
        },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                    <img
                        src={Theme.icons.icn_view_list}
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        className='cursor-pointer'
                        onClick={() => navigate(`/jive/viewServiceBooking/${record?.id}`)}
                    />
                </div>
            ),
            key: 'action',
        },
    ];


    const { data, error, isLoading } = useQuery(
        ['getAllAdminServiceOrder', activeKey, pageNumber, pageSize, search, sortOrder],
        () => getAllAdminServiceOrder({ Status: activeKey, PageNumber: pageNumber, PageSize: pageSize, Search: search, SortColumn: 'Id', SortOrder: sortOrder }),
        {
            // keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );


    return (
        <>
            <p className="page-heading">{t("Service Booking")}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

            <div className="d-flex justify-content-between align-items-center" >
                <p className="m-0 list-heading">{`Booking List`}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    <div className="calendar-div" style={{ backgroundColor: '#D0C64F' }} onClick={() => handleSorting()}>
                        <img src={Theme.icons.icn_sorting} alt="icn_user_add"
                            style={{
                                height: '20px',
                                transform: sortOrder === 'z' ? 'rotate(0deg)' : 'rotate(180deg)', // Rotates the icon by 180 degrees for ascending
                                transition: 'transform 0.3s ease-in-out' // Smooth transition for the rotation
                            }}
                        />
                    </div>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default ServiceBookingList;
