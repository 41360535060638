import React, { useState } from "react";
import CustomTabs from "../../../components/common/CTabs";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Switch, Tag } from "antd";
import AlertModal from "../../../components/common/AlertModal";
import CustomAlert from "../../../components/common/CAlert";
import { getAllFreelancer } from "../../../services/freelancer";
import _ from 'lodash';

const tabs = [
    { key: 'Pending', label: 'Pending Request' },
    { key: 'Approved', label: 'Approved Request' },
];

const ManageFreelancerList: React.FC = () => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

      // Get activeKey, pageNumber, and pageSize from URL search params
      const activeKey = searchParams.get('Status') || 'Pending';
      const pageNumber = Number(searchParams.get('PageNumber')) || 1;
      const pageSize = Number(searchParams.get('PageSize')) || 10;
      const search = searchParams.get('Search') || "";
  
      // Update search params when the tab changes
      const handleTabChange = (key: string) => {
          setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
      };
  
      // Pagination handling
      const handleTableChange = (page: number, pageSize: number) => {
          setSearchParams({ Status: activeKey, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
      };
  
      const handleSearchChange =  _.debounce((e:any) => {
          setSearchParams({ Status: activeKey, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
      },1000)


    const handleSwitchChange = (user: any) => {
        setUserWillActive({ show: true, status: user?.active, id: user?.id });
    };

    const columns: ColumnsType = [
        {
            title: t("id"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("freelancer_name"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("email_address"),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t("phone_number"),
            dataIndex: 'phoneNumber',
            key: 'joiningDate',
        },
        {
            title: t("status"),
            dataIndex: 'status',
            key: 'roleName',
            render: (text, record) => (
                <Tag
                    color={record?.active ? Theme.colors.success : 'red'}
                    className="status-tag"
                    style={{ borderColor: text === 'Approved' ? Theme.colors.success : Theme.colors.tertiary }}
                >
                    <span style={{ fontSize: '20px', lineHeight: '1', color: text === 'Approved' ? Theme.colors.success : Theme.colors.tertiary }}>•</span>
                    <span style={{ color: text === 'Approved' ? Theme.colors.success : Theme.colors.tertiary }}>{text}</span>
                </Tag>
            )
        },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                    <img
                        src={Theme.icons.icn_view_list}
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        className='cursor-pointer'
                        // onClick={() => navigate('/jive/viewFreelancer', { state: { record } })}
                    />
                    <Switch
                        checked={record?.active}
                        onChange={() => handleSwitchChange(record)}
                        className='mx-2'
                    />
                </div>
            ),
            key: 'action',
        },
    ];

    const credentials = {
        Status: activeKey,
        PageNumber: 1,
        PageSize: 10,
    };

    const { data, error, isLoading } = useQuery(
        ['freelancerUsers', activeKey, pageNumber, pageSize, search],
        () => getAllFreelancer({ Status: activeKey, PageNumber: pageNumber, PageSize: pageSize, Search: search}),
        {
            // keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );
    // const { mutate, isLoading: statusLoading } = useMutation(changeCustomerStatus, {
    //     onMutate: async (newStatus: any) => {

    //         await queryClient.cancelQueries(['adminUsers', activeKey]);
    //         const previousData = queryClient.getQueryData(['adminUsers', activeKey]);
    //         return { previousData };
    //     },
    //     onError: (err: any, newStatus, context) => {
    //         queryClient.setQueryData(['adminUsers', activeKey], context?.previousData);
    //         setAlert({ message: err?.message || t("Failed to update status"), type: 'error', visible: true });
    //     },
    //     onSettled: () => {
    //         queryClient.invalidateQueries(['adminUsers', activeKey]);
    //     },
    //     onSuccess: () => {
    //         setAlert({ message: t("Status Updated successfully!"), type: 'success', visible: true });
    //     },
    // });

    const handleModalOk = () => {
        if (userWillActive.id !== null) {
            const newStatus = !userWillActive.status;
            // mutate({ id: userWillActive.id, status: newStatus });
        }
        setUserWillActive({ show: false, status: false, id: null });
    };

    const handleModalCancel = () => {
        setUserWillActive({ show: false, status: false, id: null });
    };

    return (
        <>
            <p className="page-heading">{t("manage_freelancer")}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

            <div className="d-flex justify-content-between align-items-center" >
                <p className="m-0 list-heading">{t("all_freelancer_list")}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    <div className="calendar-div" style={{ backgroundColor: '#D0C64F' }}>
                        <img src={Theme.icons.icn_sorting} alt="icn_user_add" style={{ height: '20px' }} />
                    </div>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger:false,
                    showQuickJumper:false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />

            <AlertModal
                visible={userWillActive?.show}
                message={t(`Are you sure you want to ${userWillActive?.status ? 'deactivate' : 'activate'} this user?`)}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default ManageFreelancerList;
