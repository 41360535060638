import React, { useState } from 'react'
import { Theme } from '../../Theme'
import CustomAlert from '../../components/common/CAlert'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { getAdminServiceById } from '../../services/service'
import CButton from '../../components/common/CButton'
import { formatFileSize } from '../../utils/functions'

const ViewService: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { t } = useTranslation()
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  console.log('params', params)

  const { data, isLoading, error } = useQuery(['serviceById', params?.id], () =>
    getAdminServiceById({ Id: params?.id })
  )

  const {
    serviceName,
    serviceNameAr,
    categoryName,
    vendorFreelancerName,
    price,
    providerContact,
    serviceLocationAr,
    serviceDate,
    serviceStartTime,
    serviceEndTime,
    serviceLocation,
    features,
    description,
    featuresAr,
    descriptionAr,
    serviceImages,
    cityName
  } = data?.data || {}

  return (
    <div style={{ padding: '20px', color: Theme.colors.black }}>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <img
            src={Theme.icons.icn_arrow_back}
            alt='icn_user_add'
            className='cursor-pointer'
            style={{ height: '20px' }}
            onClick={() => navigate(`/jive/servicesAndPackagesList`)}
          />
          <p className='list-heading m-0 mx-2'>{vendorFreelancerName}</p>
        </div>
      </div>
      <div
        className='mt-3'
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <p className='user-name m-0'>{t('Service Details')}</p>
          <CButton
            className='btn-unfilled'
            onClick={() =>
              navigate('/jive/createService', {
                state: {
                  id: params.id,
                  vendorFreeLancerName: vendorFreelancerName,
                  record: data?.data
                }
              })
            }
          >
            <img
              src={Theme.icons.icn_edit}
              alt='icn_user_add'
              style={{ height: '15px' }}
            />
            Edit
          </CButton>
        </div>
        <div
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <div className='row mt-4'>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Vendor Name')}</p>
              <p className='detail-value'>{vendorFreelancerName}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Contact Provider')}</p>
              <p className='detail-value'>{providerContact}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service Name')}</p>
              <p className='detail-value'>{serviceName}</p>
            </div>
            <div className='col-lg-3 '>
              <p className='detail-label m-0'>{t('اسم الخدمة')}</p>
              <p className='detail-value '>
                {serviceNameAr ? serviceNameAr : '-'}
              </p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Category')}</p>
              <p className='detail-value'>{categoryName}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Price')}</p>
              <p className='detail-value'>{`SAR ${price}`}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service Date')}</p>
              <p className='detail-value'>{serviceDate?.split('T')[0]}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Start Time')}</p>
              <p className='detail-value'>{serviceStartTime}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('End Time')}</p>
              <p className='detail-value'>{serviceEndTime}</p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service Location')}</p>
              <p className='detail-value'>{serviceLocation}</p>
            </div>
            <div className='col-lg-3 '>
              <p className='detail-label m-0'>{t('موقع الخدمة')}</p>
              <p className='detail-value'>
                {serviceLocationAr ? serviceLocationAr : '-'}
              </p>
            </div>
            <div className='col-lg-3'>
              <p className='detail-label m-0'>{t('Service City')}</p>
              <p className='detail-value'>{cityName}</p>
            </div>
            <div className='col-lg-8'>
              <p className='detail-label m-0'>{t('Features')}</p>
              <p className='detail-value'>{features}</p>
            </div>
            <div className='col-lg-8'>
              <p className='detail-label m-0'>{t('Descriptions')}</p>
              <p className='detail-value'>{description}</p>
            </div>
            <div className='col-lg-12 dir-rtl'>
              <p className='detail-label m-0'>{t('Features (ar)')}</p>
              <p className='detail-value'>{featuresAr ? featuresAr : '-'}</p>
            </div>
            <div className='col-lg-12 dir-rtl'>
              <p className='detail-label m-0'>{t('Descriptions (ar)')}</p>
              <p className='detail-value'>
                {descriptionAr ? descriptionAr : '-'}
              </p>
            </div>
          </div>
        </div>

        <div
          className='mt-3'
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name m-0'>{t('Service Images')}</p>
          <div className='row'>
            {serviceImages?.map((file: any, index: number) => (
              <div className='col-lg-3' key={index}>
                <div className='d-flex align-items-center p-3 preview-image-view-container mt-3'>
                  <div className='d-flex align-items-center'>
                    <img
                      src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                      alt={file.originalFileName}
                      className='category-icon mb-0'
                      style={{ width: '50px', height: '50px' }}
                    />
                    <div className='mx-2'>
                      <p
                        className='detail-label m-0'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {file.originalFileName}
                      </p>
                      <p className='detail-label m-0'>
                        {formatFileSize(file.fileLength)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  )
}

export default ViewService
